import { render, staticRenderFns } from "./beans-shopping.vue?vue&type=template&id=55e8e228&scoped=true"
import script from "./beans-shopping.vue?vue&type=script&lang=js"
export * from "./beans-shopping.vue?vue&type=script&lang=js"
import style0 from "./beans-shopping.vue?vue&type=style&index=0&id=55e8e228&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55e8e228",
  null
  
)

export default component.exports