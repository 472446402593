<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
  >
    <van-grid :column-num="2" gutter="12" :border="false">
      <van-grid-item
        icon="photo-o"
        text="文字"
        @click="goodsInfo(item.id)"
        v-for="(item, i_key) in goods_list"
        :key="i_key"
      >
        <template default>
          <div class="goods-layout">
            <div class="img-box">
              <img :src="item.cover_img" />
            </div>
            <div class="goods-detail">
              <div class="title">{{ item.good_name }}</div>
              <div style="color: #e61f18">{{ item.price }}个公益豆</div>
              <div class="quantity">已兑换{{ item.sales }}件</div>
            </div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </van-list>
</template>

<script>
import { getBenefitList } from "@/api/benefit";
export default {
  name: "beans-goods",
  props: ["shopId", "orderBy", "scope"],
  data() {
    return {
      goods_list: [],
      page: 1,
      total: 100,
      loading: false,
      finished: false,
      isAjax: false,
    };
  },
  watch: {
    "_props.shopId": function () {
      this.reloadGoods();
    },
    "_props.orderBy": function () {
      this.reloadGoods();
    },
    "_props.scope": function () {
      this.reloadGoods();
    },
  },
  methods: {
    onLoad() {
      this.getBenefitList();
    },
    goodsInfo(benefitId) {
      console.log("-------公益豆列表ID----------", benefitId);
      this.$router.push({
        name: "beans-details",
        query: {
          goodsId: benefitId,
          type: 3,
        },
      });
    },
    reloadGoods() {
      this.total = 100;
      this.page = 1;
      this.goods_list = [];
      this.isAjax = false;
      this.finished = false;
      this.getBenefitList();
    },
    async getBenefitList() {
      if ( this.isAjax || this.shopId * 1 === 0) {
        return false;
      }
      this.isAjax = true;
      const res = await getBenefitList({
        shop_id: this.shopId,
        order_by: this.orderBy,
        scope: this.scope,
      });
      // console.log(res.data.list);
      if (res.data.list.length <= 0) {
        this.$emit("showBlank", true);
      } else {
        this.$emit("showBlank", false);
      }
      if (res.code * 1 === 1) {
        ++this.page;
        this.goods_list = this.goods_list.concat(res.data.list);
        //添加重复数据
        let isRepetition=true;
        while (isRepetition){
          if(this.goods_list.length<10  && this.goods_list.length>0){
            this.goods_list = this.goods_list.concat( this.goodsList.data.list);
          }else {
            isRepetition=false;
          }
        }

        this.total = res.data.total;
        this.loading = false;
        if (this.goods_list.length >= this.total) {
          // this.finished = true;
          this.page = 1;

        }
      }
      this.isAjax = false;
    },
  },
};
</script>

<style lang="less" scoped>
// 公共样式
img {
  width: 100%;
  display: block;
}
// vant组件样式修改
/deep/.van-grid-item__content {
  padding: 0;
  border-radius: 16px;
}
/deep/.van-grid-item {
  width: 188px;
}
.goods-layout {
  width: 188px;
  .img-box {
    width: 100%;
    img {
      width: 188px;
      height: 188px;
      border-radius: 10px 10px 0 0;
    }
  }
}
.van-list {
  margin-top: 12px;
}
// 商品详情
.goods-detail {
  padding: 10px;
  font-size: 14px;
  .title {
    font-size: 16px;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .quantity {
    color: #9b9b9b;
    font-size: 13px;
    margin-top: 6px;
  }
}
</style>
