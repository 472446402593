<template>
  <div class="block">
    <Search class="search-layout" @setSelectStr="setSelectStr">
      <div slot="left" class="search-left">
        <van-icon name="arrow-left" @click="close" size="20" color="#9B9B9B" />
      </div>
      <div slot="right" class="search-right">
        <van-button type="danger" round block @click="getMyBenefitList">
          搜索
        </van-button>
      </div>
    </Search>
    <div class="layout-page">
      <div class="site-card" v-for="(item, index) in list" :key="index">
        <div class="shop-distance">
          <span class="shop-name">{{ item.shop_name }}</span>
          <span>{{ item.distance }} km</span>
        </div>
        <div class="select-site">
          <van-radio-group v-model="radio" checked-color="#ED301D">
            <!-- 根据索引选择单选框，后期改为ID -->
            <van-radio
              :name="item.shop_id"
              label-position="left"
              @click="onRadio(item.shop_id)"
            >
              {{ item.city_name }}{{ item.address }}
            </van-radio>
          </van-radio-group>
        </div>
        <div class="usable-beans">可用公益豆：{{ item.bean_money || 0 }}</div>
      </div>
    </div>
    <div class="button-box">
      <van-button class="button" block round type="danger" @click="onConfirm">
        确认自取店铺
      </van-button>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search/search";
import { getMyBenefitList } from "@/api/benefit";
// import loginPageVue from "../../login/login-page.vue";
export default {
  name: "select-shop",
  components: {
    Search,
  },
  props: ["showOverlay", "lon", "lat", "shopId"],
  data() {
    return {
      radio: 1,
      list: [],
      str: "",
    };
  },
  mounted() {
    this.radio = this.shopId;
    this.getMyBenefitList();
  },
  methods: {
    setSelectStr(str) {
      this.str = str;
    },
    onConfirm() {
      this.$emit("onClose", this.radio);
    },
    onRadio(id) {
      this.radio = id;
    },
    async getMyBenefitList() {
      let res = await getMyBenefitList({
        shop_name: this.str,
        lon: this.lon,
        lat: this.lat,
      });
      if (res.code * 1 === 1) {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].distance = this.getDistance(
            res.data[i].lat,
            res.data[i].lon
          );
        }
        this.list = res.data;
      }
    },
    getDistance(lat2, lng2) {
      let lat1 = this.lat;
      let lng1 = this.lon;
      let radLat1 = (lat1 * Math.PI) / 180.0;
      let radLat2 = (lat2 * Math.PI) / 180.0;
      let a = radLat1 - radLat2;
      let b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      let s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      return s;
    },
    close() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="less" scoped>
// vant 组件样式
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
}
.van-radio {
  display: flex;
  justify-content: space-between;
}
/deep/.van-radio__label {
  font-size: 16px;
  color: #6f6f6f;
}
// 自定义内容样式
.search-layout {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8000;
  border-bottom: 1px solid rgb(245, 245, 245);
}
.layout-page {
  padding: 71px 12px;
}
.site-card {
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 12px;
  .shop-distance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 16px;
      color: #6f6f6f;
    }
    .shop-name {
      font-size: 17px;
      font-weight: 500;
    }
  }
  .select-site {
    padding: 10px 0;
    color: #6f6f6f;
  }
  .usable-beans {
    font-size: 16px;
    color: #aaa;
  }
}
.button-box {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 63px;
  padding: 0 12px;
  background-color: #fff;
  border-top: 1px solid rgb(245, 245, 245);
  .button {
    height: 52px;
    font-size: 17px;
  }
}
</style>
