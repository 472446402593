<template>
  <div>
    <!-- 导航栏 -->
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        size="20"
        class="return"
        color="#9B9B9B"
        @click="$router.go(-1)"
      />
      <div class="address-title" @click="onSelectShop">
        <div class="title-box" v-if="!isBlank">
          <span>{{ shopInfo.shop_name || "请选择门店" }}</span>
          <van-icon name="arrow" size="16" />
        </div>
        <span v-else>公益豆商城</span>
      </div>
    </div>
    <div v-if="!isBlank">
      <div class="charity-beans">
        <span>可用公益豆：{{ shopInfo.bean_money }}</span>
        <div class="beans-detail-btn" @click="publicBeans">公益豆明细</div>
      </div>
      <!-- 排序菜单 -->
      <van-dropdown-menu>
        <van-dropdown-item v-model="rank" :options="option1" />
        <van-dropdown-item v-model="beansRank" :options="option2" />
      </van-dropdown-menu>
      <!-- 公益豆商品列表 -->
      <BeansGoods
        :shopId="shopId"
        :orderBy="rank"
        :scope="beansRank"
        @showBlank="showBlank"
      ></BeansGoods>
    </div>
    <!-- 地址选择列表 -->
    <SelectShop
      v-if="showOverlay"
      :shopId="shopId"
      :lon="lon"
      :lat="lat"
      @onClose="closeShow"
    ></SelectShop>
    <!--地图定位-->
    <TxMap ref="txMap" v-if="isShowMap" @setLocation="setLocation"></TxMap>
    <div class="blank-page" v-if="isBlank">
      <div class="block">
        <img src="~@/assets/blank/beans-shopping.png" class="blank-img" />
        <span>当前没有可兑换公益豆商品店铺</span>
        <div class="button-blank" @click="goToLocality">附近店铺</div>
      </div>
    </div>
  </div>
</template>

<script>
import BeansGoods from "@/components/beans-goods/beans-goods";
import SelectShop from "./select-shop";
import { getDefaultShopInfo } from "@/api/benefit";
import TxMap from "@/components/map/tx-map";

export default {
  name: "beans-shopping",
  components: {
    BeansGoods,
    SelectShop,
    TxMap,
  },
  data() {
    return {
      showOverlay: false, // 遮罩层控制
      rank: 0, // 匹配标识符
      beansRank: 0, // 匹配标识符
      prompt: "当前没有可兑换公益豆商品店铺",
      option1: [
        { text: "默认排序", value: 0 },
        { text: "公益豆从低到高", value: 1 },
        { text: "公益豆从高到低", value: 2 },
        { text: "兑换量从高到低", value: 3 },
      ],
      option2: [
        { text: "全部公益豆", value: 0 },
        { text: "1-100", value: 1 },
        { text: "101-300", value: 2 },
        { text: "301-500", value: 3 },
      ],
      shopInfo: { id: 0, bean_money: 0 },
      shopId:
        this.$route.query.shopId === undefined ? 0 : this.$route.query.shopId,
      lon: 0,
      lat: 0,
      location: {},
      isShowMap: false,
      isBlank: false,
    };
  },
  mounted() {
    if (this.shopId * 1 > 0) {
      this.getDefaultShopInfo();
    } else {
      this.isShowMap = true;
    }
  },
  methods: {
    goToLocality() {
      this.$router.push({ name: "native-live" });
    },
    showBlank(bool) {
      this.isBlank = bool;
    },
    publicBeans() {
      // 去公益豆明细
      this.$router.push({
        path: "/beans-consume",
        query: { shopId: this.shopInfo.id, shopName: this.shopInfo.shop_name },
      });
    },
    setLocation(location) {
      this.location = location;
      this.lat = location.lat;
      this.lon = location.lng;
      this.getDefaultShopInfo();
    },
    onSelectShop() {
      this.showOverlay = true;
    },
    async getDefaultShopInfo() {
      //获取店铺详情
      const ret = await getDefaultShopInfo({
        shop_id: this.shopId,
        lon: this.lon,
        lat: this.lat,
      });
      if (ret.code * 1 === 1) {
        this.shopInfo = ret.data;
        this.shopId = this.shopInfo.id;
      }
    },
    closeShow(radio) {
      this.showOverlay = false;
      if (radio) {
        this.shopId = radio;
        this.getDefaultShopInfo();
      }
    },
  },
};
</script>

<style lang="less" scoped>
// vant组件菜单栏
/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
  height: 57px;
}

.nav-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  background-color: #fff;
  border-bottom: 1px solid rgb(243, 243, 243);
  box-sizing: border-box;
  z-index: 3;
  .return {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  .address-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 70px;
    .title-box {
      display: flex;
      align-items: center;
    }
    span {
      font-size: 16px;
      padding-right: 10px;
    }
  }
}

.charity-beans {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 0 12px;
  color: #6f6f6f;
  font-size: 18px;
  height: 57px;
  margin-top: 57px;
  .beans-detail-btn {
    width: 101px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #e61f18;
    font-size: 16px;
    border-radius: 18px;
    color: #e61f18;
    text-align: center;
    line-height: 36px;
    &:active {
      background-color: rgb(243, 243, 243);
    }
  }
}
.blank-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #fff;
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .blank-img {
      display: block;
      width: 155px;
      height: 155px;
    }
    span {
      margin: 18px 0 26px 0;
      font-size: 18px;
      color: #aaaaaa;
    }
  }
  .button-blank {
    width: 207px;
    height: 52px;
    background: linear-gradient(260deg, #ed301d 0%, #fe5876 100%);
    box-shadow: 0px 2px 4px rgba(199, 8, 8, 0.26);
    opacity: 1;
    border-radius: 28px;
    line-height: 52px;
    text-align: center;
    font-size: 21px;
    color: #fff;
  }
}
/deep/.van-dropdown-menu__item {
  flex: none;
  margin-left: 26px;
  &:first-child {
    margin-left: 8px;
  }
}
/deep/.van-ellipsis {
  font-size: 18px;
}
/deep/.van-cell__title {
  font-size: 17px;
}
</style>
